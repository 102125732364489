/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable lines-between-class-members */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from 'moment';
import Formatters from '@/shared/formatters/formatters';

const formatter = new Formatters();
const dateNow = moment(new Date()).format('DD/MM/YYYY');
const maxFileSize = 52428800;
export default class Rules {
  required = (value) => !!value || '*Preenchimento obrigatório';
  valid = (value) => (!!value && value.replace(/\s+/g, '').length > 0) || '*Preenchimento obrigatório';
  requiredArray = (value) => (value && value.length > 0) || '*Preenchimento obrigatório';
  cns = (value) => (value && value > 0 && value?.length === 15) || '*CNS inválido';
  name = (value) => (value && value.length >= 3) || '*Nome muito curto';  
  hasEmptyCaracter = (value) => (value && !value.includes(" ")) || '*Espaço em branco inválido';
  sizeLimit = (value,limit) => (value && value.length > 0 && value.length <= limit) || `*Limite de caracteres excedido. Máximo ${limit} caracteres.`;
  cellphone = (value) => (value && value?.length === 15) || '*Número inválido';
  telephone = (value) => (value && value?.length === 14) || '*Número inválido';
  dnv = (value) => !value || (value && value?.length === 13) || '*DNV inválido';
  validateWorkCard = (value) => (value ? value.length === 7 || '*Número inválido' : true);
  validatePis = (value) => (value ? value.length === 14 || '*Número inválido' : true);
  requiredDate = (value) => (value && value.length === 10) || '*Data inválida';
  isAfterSpecificYear = (value) => moment(value, 'DD/MM/YYYY', true).isAfter('01/01/1899', 'year') || '*Data inválida';
  isBeforeDateNow = (value) => moment(value, 'DD/MM/YYYY', true).isBefore() || `Data deve ser menor que ${dateNow}`;
  isAfterDateNow = (value) => moment(value, 'DD/MM/YYYY', true).isAfter() || `Data deve ser maior que ${dateNow}`;
  validateSalary = (value) => (value ? value?.length <= 25 || '*Número inválido' : true);
  isBoolean = (value) => (value === true || value === false) || '*Preenchimento obrigatório';

  hasAtLeastOneField = (obj, fields) => {
    return fields.some(key => {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        return Boolean(value);
      }
      return false;
    });
};

  isDateValid = (value) => {
    if (!value || value === '') return true;

    if (!moment(value, 'DD/MM/YYYY', true).isValid()) return '*Data inválida';

    return true;
  };

  cpf = (value) => {
    if (value) {
      let add;
      let rev;
      value = value?.replace(/\.|-/g, '');
      if (value === '') return '*CPF inválido';
      // Elimina CPFs invalidos conhecidos
      if (
        value?.length !== 11
      || value === '00000000000'
      || value === '11111111111'
      || value === '22222222222'
      || value === '33333333333'
      || value === '44444444444'
      || value === '55555555555'
      || value === '66666666666'
      || value === '77777777777'
      || value === '88888888888'
      || value === '99999999999'
      ) {
        return '*CPF inválido';
      }
      // Valida 1o digito
      add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(value.charAt(i)) * (10 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(value.charAt(9))) return '*CPF inválido';
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(value.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(value.charAt(10))) return '*CPF inválido';
      return true;
    }
    return true;
  }

  cpfValidation = (value) => {
    if (value) {
      let add;
      let rev;
      value = value?.replace(/\.|-/g, '');
      if (value === '') return false;

      if (
        value?.length !== 11
      || value === '00000000000'
      || value === '11111111111'
      || value === '22222222222'
      || value === '33333333333'
      || value === '44444444444'
      || value === '55555555555'
      || value === '66666666666'
      || value === '77777777777'
      || value === '88888888888'
      || value === '99999999999'
      ) {
        return false;
      }

      add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(value.charAt(i)) * (10 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(value.charAt(9))) return false;

      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(value.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(value.charAt(10))) return false;
      return true;
    }
    return true;
  }

  getMaxFileSize(){
    return maxFileSize;
  }

  getMaxFileSizeMB(){
    return (maxFileSize / 1048576).toFixed(2) + ' MB';
  }
  cnpj = (value) => {
    if (value && value.length === 18) {
      value = value.replace(/[^\d]+/g, '');
      if (value === '00000000000000'
          || value === '11111111111111'
          || value === '22222222222222'
          || value === '33333333333333'
          || value === '44444444444444'
          || value === '55555555555555'
          || value === '66666666666666'
          || value === '77777777777777'
          || value === '88888888888888'
          || value === '99999999999999'
      ) return 'CNPJ Inválido';

      let size = value.length - 2;
      let numbers = value.substring(0, size);
      const digits = value.substring(size);
      let sum = 0;
      let position = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * position--;
        if (position < 2) position = 9;
      }
      let result = sum % 11 < 2 ? 0 : (11 - (sum % 11));
      if (result !== Number(digits.charAt(0))) return 'CNPJ Inválido';

      size += 1;
      numbers = value.substring(0, size);
      sum = 0;
      position = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * position--;
        if (position < 2) position = 9;
      }
      result = sum % 11 < 2 ? 0 : (11 - (sum % 11));
      if (result !== Number(digits.charAt(1))) return 'CNPJ Inválido';

      return true;
    }
    return 'CNPJ Inválido';
  }
  email = (value) => {
    if (value && value.length > 0) {
      const pattern = /^(([^<>(){}!#$%^=+*\[\]\\.,;:\s@"]+(\.[^<>(){}!#$%^=+*\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'E-mail inválido';
    }
    return true;
  }
  criticizedFields(fieldsList) {
    if (fieldsList !== null) {
      const newRules = fieldsList?.map((item) => ({ field: item.field }));

      return Array.from(newRules, (item) => item.field);
    }
    return [];
  }
  rgMaxDigit(typeDocument, field) {
    if (typeDocument === 'RG') {
      return field?.length <= 15 || 'RG Inválido';
    }
    return true;
  }
  endDateIsGreaterThanOrEqualToStartDate(startDate, endDate, message) {
    if (startDate && endDate) {
      const dateStart = formatter.formatDateBRtoString(startDate);
      const dateEnd = formatter.formatDateBRtoString(endDate);
      return moment(dateEnd).isSameOrAfter(dateStart) || message;
    }
    return true;
  }
  isAlphanumeric = (value) => {
    if (value) {
      const pattern = /^[a-zA-ZÀ-ÿ0-9\s]*$/;
      return pattern.test(value) || 'Caracter inválido';
    }
    return true;
  };

  validateIsAlphanumeric = (value) => {
    if (value) {
      const pattern = /^[a-zA-ZA-zÀ-úA-zÀ-ÿA-Za-zÀ-ÿA-Za-zÀ-ÖØ-öø-ÿ\s]*$/;
      return pattern.test(value) || 'Nome inválido';
    }
    return true;
  }
  validateNotSpecialCharacters = (value) => {
    if (value && value.length > 0) {
      const pattern = /^[a-zA-ZA-zÀ-úA-zÀ-ÿA-Za-zÀ-ÿA-Za-zÀ-ÖØ-öø-ÿ0-9\s\\.\\,\\:\\;]*$/;
      return pattern.test(value) || 'Nome inválido';
    }
    return true;
  }
  validateNoQuotes = (value) => {
    if (value && value.length > 0) {
      const pattern = /^[^'"]*$/;
      return pattern.test(value) || 'As aspas simples e as aspas duplas não são permitidas.';
    }
    return true;
  }
  validateNegativeDecimal(value, type) {
    let result = true;
    switch (type) {
      case 'float':
        if (value) {
          const format = value.replace(',', '.');
          if (parseFloat(format) < 0) {
            result = '*Apenas valores positivos';
          }
        }
        break;

      case 'int':
        if (value) {
          if (parseInt(value) < 0) {
            result = '*Apenas valores positivos';
          }
        }
        break;

      default:
        break;
    }
    return result;
  }
  validatesNumberGreaterThanOrEqualToZero(value) {
    if (value === 0) {
      return '*Número inválido';
    }
    return true;
  }
  validateUploadFiles(item) {
    if (item) {
      if (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp') {
        return 'Os formatos de arquivos permitidos são: PDF, JPG, JPEG, PNG, BMP, TIFF e WEBP.';
      }
      if (item.file && (!this.isValidFileSize(item.file.size))) {
        return 'O tamanho do arquivo não pode ser superior a ' + this.getMaxFileSizeMB();
      }
    }
    return true;
  }
  validateAllFiles(items) {
    if (items.length === 0) return false;
    let result = true
    items.map(item => {
      if (![
        'application/pdf',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
      ].some(type => type === item.type)) {
        result = false;
      }
      if (item && (!this.isValidFileSize(item.size))) {
        result = false;
      }
    })
    return result;
  }
  isValidFileSize(size){
    if(size && size > maxFileSize){
      return false;
    }
    return true;
  }
  verifyNegative(value, ref) {
    const format = value.replace(',', '.');
    // eslint-disable-next-line no-restricted-globals
    if (parseFloat(format) < 0 || isNaN(value)) {
      document.getElementById(ref).focus();
    }
  }
  isFocusVerifyNegative(focus, value) {
    // eslint-disable-next-line no-restricted-globals
    if (focus && isNaN(value)) {
      return true;
    }
    return false;
  }
  validationGreaterThanOrEqualToZero(value) {
    let valueNumber = 0;
    if (value) {
      valueNumber = Number(value);
      if (valueNumber < 0) {
        return 'Valor deve ser maior que 0';
      }
    }
    return true;
  }
  validateUrl(value) {
    const pattern = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    return value && !!pattern.test(value) || "Url invalida";
  }
  checkRepeatOrders(order, props) {
    if (order) {
      const transformationExisting = props.filter((transformation) => transformation.isChecked && transformation && transformation.order === order);
      if (transformationExisting && transformationExisting.length > 1) {
        return 'Ordens não podem ser iguais';
      }
    }
    return true;
  }
  handleSelectionLimit(items, max) {
    if (items && max) {
      if (items.length > max) return `Por favor, selecione no máximo ${max} itens`;
    }
    return true;
  }
  checkDateRange(startDate, endDate) {
    if (startDate && endDate) {
      const [dayStart, monthStart, yearStart] = startDate.split('/');
      const newDataStartString = `${yearStart}-${monthStart ? monthStart.padStart(2, '0') : null}-${dayStart ? dayStart.padStart(2, '0') : null}`;
      const dateStart = new Date(newDataStartString);

      const [dayEnd, monthEnd, yearEnd] = endDate.split('/');
      const newDataEndString = `${yearEnd}-${monthEnd ? monthEnd.padStart(2, '0') : null}-${dayEnd ? dayEnd.padStart(2, '0') : null}`;
      const dateEnd = new Date(newDataEndString);

      const diff = Math.abs(dateEnd - dateStart);
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (days > 90) {
        return 'Por favor, selecione datas até 90 dias.';
      }
    }
    return true;
  }
  isEmptyArray(array) {
    return Array.isArray(array) && array.length === 0;
  }
  checkExpiredToken(tokenActive) {
    const tokenWithoutBearer = tokenActive.replace('Bearer ', '').replace('\\', '');
    const token = this.parseJwt(tokenWithoutBearer);
    const tokenExpDateMillis = moment(token.exp * 1000).valueOf();
    const currentUnixTimestamp = Math.round(+new Date() / 1000);

    return (tokenExpDateMillis - currentUnixTimestamp * 1000) / (1000 * 60) < 5;
  }
  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  }
  validateCompanyName(item) {
    if (item && item.length > 0) {
      const pattern = /^[^;"]*$/;
      return pattern.test(item) || 'Nome da empresa inválido.';
    }
    
    return true;
  }
  validateMaxTextSize(item, maxSize) {
    if (item && item.length > 0 && item.length > maxSize) {
      return `Limite máximo de caracteres atingido. Máximo: ${maxSize}.`;
    }

    return true;

  }

}
